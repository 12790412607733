.bassket{
  width: 100%;
  height: 100vh;
  background-color: #1e1e1e;
  padding-top: 80px;
  margin-bottom: 320px;

}
/* Cart */
.cart-container {
  width: 100%;
  padding: 2rem 4rem;
  background: #1e1e1e;
  color: #f9f9f9;
}
.margin{
  margin-left: 30%;
}
.p-link{
  cursor: pointer;
  cursor: pointer;
  z-index: 100;
  width: 150px;
}
.cart-container h2 {
  font-weight: 700; 
  font-size: 30px;
  text-align: center;
  margin-bottom: 2rem;
  font-family: cursive; 
}

.cart-container .titles {
  margin: 2rem 0 1rem 0;
}

.cart-container .titles h3 {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.cart-item,
.cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}

.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding: 1rem 0;
}

/* Add styling for the product titles */
.cart-container .titles .product-title {
  padding-left: 0.5rem;
}

.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
}

.cart-item .cart-product {
  display: flex;
}

.cart-item .cart-product img {
  width: 100px;
  max-width: 100%;
  margin-right: 1rem;
}

.cart-item .cart-product h3 {
  font-weight: 400;
}

.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: gray;
}

.cart-item .cart-product button:hover {
  color: #f9f9f9; /* Change the color on hover for better visibility */
}

.cart-item .cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}

.cart-item .cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}

.cart-item .cart-product-quantity .count {
  padding: 0.7rem 0;
}

.cart-item .cart-product-total-price {
  padding-right: 0.5rem;
  justify-self: right;
  font-weight: 700;
}

/* cart summary */
.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
  
  
}

.cart-summary .clear-btn {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
}

.cart-checkout {
  width: 270px;
  max-width: 100%;

}

.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.cart-checkout .amount {
  font-weight: 700;
}

.cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}

.cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 700; /* Increased font weight for emphasis */
  letter-spacing: 1.15px;
  background-color: #4b70e2;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background 0.3s; /* Added transition for a smoother background change */
}

.cart-checkout button:hover {
  background-color: #3752a1; /* Darker blue on hover */
}

/* Responsive */
@media (max-width: 665px) {
  /* NavBar */
  .nav-bar {
    padding: 0 1rem;
  }

  .nav-bar h2 {
    font-size: 30px;
  }

  /* Cart */
  .cart-container {
    padding: 2rem;
  }

  .cart-container .titles {
    display: none;
  }

  .cart-item,
  .cart-container .titles {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }

  .cart-item .cart-product-total-price {
    justify-self: left;
  }

  .cart-summary {
    flex-direction: column;
  }

  .cart-summary .clear-btn {
    margin-bottom: 2rem;
  }
}
