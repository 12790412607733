.full-page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1e1e1e;
}

.product-title{
    width: 90%;
    height: 150px;
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.product-cards{
    width: 95%;
    height: 100%;
    margin-top: -20px;
}

.product-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
}
.product-card{
    background-color: transparent;
    filter: blur(0.5);
}
.add-btn{
    background-color: whitesmoke;
    color: black;
}
.search-input{
    background-color: #1e1e1e;
    height: 100%;
    width: 100%;
    
}
