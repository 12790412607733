.profile-container{
    height: 100vh;
    width: 100%;
    background-color: rgb(19, 19, 19);
    padding: 20px;
    display: flex;
}
.profile-form{
    width: 100%;
    height: calc(100vh-2rem);
    padding-left: 40px;
}