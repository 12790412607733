/* footer.css */

.frame {
  display: flex;
  justify-content: space-evenly;
  padding-top: 40px;
  background-color: #1e1e1e;
  color: #fff;
  flex-wrap: wrap;
  margin-top: 80px;
}


.frame .div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-wrapper {
  font-size: 24px; /* Adjusted font size for better fit */
  font-weight: bold;
}

.p {
  font-size: 14px;
  max-width: 300px;
}

.social {
  display: flex;
  gap: 10px;
}

.element,
.logo-fb-simple-wrapper,
.logo-instagram-wrapper,
.logo-github-wrapper {
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.element svg,
.logo-fb-simple-wrapper svg,
.logo-instagram-wrapper svg,
.logo-github-wrapper svg {
  fill: #fff;
  width: 20px; /* Adjusted icon size */
  height: 20px; /* Adjusted icon size */
}

.element:hover,
.logo-fb-simple-wrapper:hover,
.logo-instagram-wrapper:hover,
.logo-github-wrapper:hover {
  background-color: #555;
}

.element:hover svg,
.logo-fb-simple-wrapper:hover svg,
.logo-instagram-wrapper:hover svg,
.logo-github-wrapper:hover svg {
  fill: #fff;
}

.div-3 {
  color: #fff;
  font-family: Inder;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.div-3 .a{
  color: #fff;
  text-decoration: none;
}

.HELP-MENU {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
}

.about-features-works,
.about-features-works-2 {
  font-size: 14px;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.badge {
  display: flex;
  gap: 10px;
  font-size: 18px;
  color: #fff;
  margin-top: 20px;
}

.badge svg {
  width: 30px;
  height: 30px;
}



.section-heading {
  font-family: "Dancing Script", cursive;
  text-align: center;
  font-size: 26px;
  color: #fff;
}

.container2 {
  display: flex;
  justify-content: center;
  width: 20%;
  max-width: 1440px;
  margin: 0 auto;
}

.profile {
  position: relative;
  transition: all 0.3s;
}

.profile:hover {
  transform: translateY(-25px);
}

.profile img {
  max-width: 100%;
  border-radius: 50%;
  border: 5px solid #f7f7f7;
  filter: drop-shadow(-20px 0 10px rgba(0, 0, 0, 0.1));
  cursor: pointer;
}

.profile:not(:first-child) img {
  margin-left: -20px;
}

