.regi-home {
    
    border-radius: 12px !important;
    background: white !important; 
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}  
.regi-home:hover {
    box-shadow: 5px 5px 25px white !important;
    transform: scale(1.03) rotate(3deg); 
  background-color: #f0f0f0 !important;
  color: #675149 !important;
  font-family: cursive !important; 
  animation: pulse 1.5s infinite;

}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}


@keyframes pulse-shadow {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}

.regi-home:hover .register-inputs {
    opacity: 1; 
  }

.avatar-des {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(241, 240, 240, 0.3);
    font-size: 24px !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
  }
  
  .avatar-des:hover {
    transform: scale(1.1); 
    box-shadow: 0 0 20px rgba(241, 240, 240, 0.5); 
  }
  
    
.regi-home:hover .avatar-des {
    transform: rotate(360deg); 
  }

.body-me {
      background: #151515; 
     /* background: url(./backgroundsite.png);   */

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  

.signup-btn {
    border-radius: 25px !important;
    color: #fff !important;
    font-family: cursive !important;
    padding: 15px 30px;
    border: 2px solid #ff5722;
    cursor: pointer;
    overflow: hidden;
    background: linear-gradient(45deg, #f4ddd6, #171616) !important; 
    transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease, border-color 0.3s ease !important; /* Add transition for smooth effect */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important; 
    position: relative !important;
  }
  
  .signup-btn:hover {
    background: linear-gradient(45deg, #171616, #f4ddd6) !important; 
    transform: translateY(-5px) rotateY(360deg) !important; 
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2) !important;
    border-color: #27241f; 
  }
  
  
  
  
.alreday-acc {
    font-size: 14px;
    font-family: cursive !important;
    color: #3f51b5; 
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .alreday-acc:hover {
    color: #1a237e; 
    text-shadow: 0 0 8px rgba(63, 81, 181, 0.8); 
    transform: scale(1.05); 
  }
  
  .alreday-acc:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #3f51b5; 
    transform-origin: 0 50%;
    transform: scaleX(0); 
    transition: transform 0.3s ease;
  }
  
  .alreday-acc:hover:before {
    transform: scaleX(1); 
  }
  
  .alreday-acc:hover {
    animation: pulsate 1s infinite; 
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  .create-acc{
    font-family: cursive!important;
    font-size: 6em;
    color: #1f1e1e;
    text-shadow: 2px 2px 2px #ddd, 4px 4px 4px #ccc, 8px 8px 8px #bbb; }