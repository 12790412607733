@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app2 {
  display: flex;
  position: relative;
}

.Header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.export-btn{
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 10px 20px !important;
}

.row1{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}
.r2{
  border-radius: 25px;
}
.row2{
  margin-top: 10px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row2{
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
