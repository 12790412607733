/* .sidebar {
  width: 15%;
  position: fixed;
  height: 100%;
  border-right: 2px solid #171816; 
  background-color: #171816; 
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white; 
}

.logo-container {
  margin-bottom: 4rem;
}

.logo-container h1 {
  margin-top: 1.3rem;
  color: white; 
} */

.sidebar {
  width: 15%;
  position: fixed;
  height: 100%;
  border-right: 2px solid #171816; /* Adjust border color to match the dark background */
  background-color: #171816; /* Dark background color */
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white; /* Text color */
}

.logo-container {
  margin-bottom: 4rem;
}

.logo-container h1 {
  margin-top: 1.3rem;
  color: white; /* Logo text color */
}
