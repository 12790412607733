.content2{
  width: 82%;
  height: 100vh;
}
.AppBar{
  background-color: #f7f7f9 !important;
  color: #000  !important;
  box-shadow: none !important;
}
.cc{
  color: #000;
}
.listHeader{
  background-color: #f7f7f9;
}
.listOne{
  background-color: #f7f7f9;
  color: #000;
  height: 100vh;
  padding-top: 20px !important;
  padding-left: 20px !important;
}
.listTwo{
  background-color: #f7f7f9;
  height: 100vh;
  width: 100%;
  color: white;
  margin-top: 65px;
}

.btn{
  margin-left: 1100px;
  background: transparent;
  font-size: 20px;
  border: none;
  font-weight: 500;
  cursor: pointer;
}


.action{

  min-width: 219px;
  display: flex;
  justify-content: space-evenly;
}

.input1{
  margin-bottom: 20px !important;
}