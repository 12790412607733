.main-checkout-page{
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    display: flex;
    justify-content: center;
    align-items: center;
}
.check-process{
    width: 100%;
    height: 600px;
    margin-top: 70px;
    display: flex;

}
.check-form{
    width: 70%;
    height: 100%;
    display: flex;
    
    align-items: center;
    margin-left: 101px;
}
.check-details{
    width: 30%;
    height: 100%;
    display: flex;
   
    align-items: center;
    margin-right: 190px;
}

.check-buy{
    display: flex;
    justify-content: space-between;
}

.back-to-shopping-link {
    display: inline-block;
    background-color: white;
    padding: 10px 15px; 
    color: black;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 10px; 
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }