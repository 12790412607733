/* * {
  margin: 0;
  padding: 0;
  font-family: 'poppins', sans-serif;
  box-sizing: border-box;
  background-color: #1E1E1E;
  color: #FFFFFF;
} */


.productdisplay {
  display: flex;
 margin-left: 100px;
  margin-top: 10rem;
  margin: 7rem;
}

.productdisplay-left {
  display: flex;
  gap: 17px;
}

.productdisplay-img-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 139px;
  height: 400px;
  
}

.productdisplay-img-list img {
  height: 163px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.productdisplay-main-img {
  width: 30rem;
  height: 700px;
  border: 2px solid #ebebeb;
  border-radius: 6px;
}

.productdisplay-right {
  margin: 0 70px;
  display: flex;
  flex-direction: column;
}

.productdisplay-right h1 {
  color: #FFF;
  font-family: cursive;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.productdispaly-right-stars {
  display: flex;
  align-items: center;
  margin-top: 13px;
  gap: 5px;
  color: #1c1c1c;
  font-size: xx-large;
  width: 24.712px;
height: 24.712px;
}

.productdispaly-right-stars i {
  color: #ffdf00;
}

.productdisplay-right-prices {
  display: flex;
  margin: 40px 0;
  gap: 30px;
  font-size: 24px;
  font-weight: 700;
}

.productdisplay-right-price-old {
  color: #818181;
  text-decoration: line-through;
}

.productdisplay-right-price-new {
  color: #ff4141;
}

.productdisplay-right-size h1 {
  margin-top: 32px;
  color: white;
  font-size: 24px;
  font-weight: 700;
}

.productdisplay-right-size {
  display: flex;
  margin: 20px 0;
  gap: 20px;
  flex-wrap: wrap;
}

.productdisplay-right-size div {
  display: flex;
  padding: 15px 20px;
  /* background-color: #f5f5f5;
  border: 1px solid #ddd; */
  border-radius: 5px;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.productdisplay-right-size div:hover {
  background-color: #140115;
}
.buttons-moins-plus{
  background-color: yellowgreen;
}

.add-to-cart-button{
  width: 20rem;
  height: 3rem;
  color: white;
  background-color: #060606;

}

.productdisplay-right-addtocart{
  display: flex;
  justify-content: space-evenly;
}
  .productdisplay-right-addtocart button {
    font-size: 16px;
    font-weight: 600;
   
   
    margin-bottom: 40px;
    border: none;
    cursor: pointer;
    border-radius: 27px;
     height: -74px;
    margin-top: 33px;
    width: 209px;
  border-radius: 27px;
  margin: 1px;

}


/* .button-moins{
  width: 100px;
  margin-left: 20px;
  margin-right: 20px;
}
.button-plus{
  width: 100px;
  margin-left: 20px;
  margin-right: 20px;
} */


.container-details{
  display: flex;
  flex-direction: column;
   font-family: 'poppins', sans-serif;
  box-sizing: border-box;
  background-color: #1E1E1E;
  color: #FFFFFF; 
}
.details-tabs{
  padding: 43px !important;
  background-color: #1e1e1e !important;

  /* padding-top: 20px; */

}
.tablist-des{
  display: flex;
  justify-content: space-around;

}

.tab1{
  width: 550px;
  /* color: white !important; */
  /* box-shadow: 5px 5px 10px #000000; */
  border-radius: 25px !important;
  font-size: larger !important;
  font-style: italic !important;
  margin-bottom: 16px !important;


}
.tab2{
  width: 550px;
  /* box-shadow: 5px 5px 10px #4a4a4a; */
  color: #a4b0ae !important;
  border-radius: 20px !important;
  font-size: larger !important;
  font-style: italic !important;
  margin-bottom: 16px !important;
}
.tab3{
  width: 550px;
  color: #a4b0ae !important;
  /* box-shadow: 5px 5px 10px #4a4a4a; */
  border-radius: 20px !important;
  font-size: larger !important;
  font-style: italic !important;
  margin-bottom: 16px !important;
}
.item1{
  height: 147px;
  box-shadow: 5px 5px 10px #4a4a4a;
  border-radius: 25px !important;
  background-color: #f3ebeb !important;
  font-size: larger;
  font-style: italic;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}
.item2{
  height: 440px;
  box-shadow: 5px 5px 10px #4a4a4a;
  border-radius: 25px !important;
  background-color: #1E1E1E !important;
}

ul{
  list-style-type: none;
}
.grid-jus{
  display: flex;
  justify-content: center;
}
.productdisplay-right-descriptions{
 color: white;
 font-size: 15px;
 .productdisplay-right-description h1{
  font-size: 17px !important;
  font-family: cursive !important;
 }

}
.productdisplay-right-descriptions h1{
  color: #963364;
  font-size: 24px;
  font-family: cursive;
  margin-bottom: 16px;
}
.buttons-moins-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; 
  width: 200px; 
  border-radius: 10px; 
  background-color: #fff; 
  color: #060606;
  padding: 05 05px; 
  border: 2px solid #000; 
}
/* 
.button-moins,
.button-plus {
  /* background-color: #000; 
  color: #000;  */
  /* font-size: 14px; 
  cursor: pointer;
  width: 20px; 
  height: 20px; 
  border-radius: 5px; 
} */ 

.buttons-moins-plus span {
  color: #000; /* Black text color for the span */
  font-size: 20px; /* Adjusted font size for the span */
}
/* 
.button-moins:hover,
.button-plus:hover {
  background-color: #fff;
  color: #FFF; 
} */

