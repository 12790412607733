 /* userProfile.css */

 .body-one {
    width: 50%;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    background-color: #151414;
  }
  

.ava-img{
    display: flex;
    justify-content: center;
    font-size: 24px !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}
.ava-img:hover {
    transform: scale(1.1); 
    box-shadow: 0 0 0px rgba(241, 240, 240, 0.5); 
    transform: rotate(360deg); 
  } 