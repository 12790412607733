.light-theme-row {
  /* Light theme row styles */
  background-color: #f0f0f0;
}

.dark-theme-row {
  /* Dark theme row styles */
  background-color: #222;
  color: #fff;
}
