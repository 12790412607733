@font-face {
    font-family: 'Monument'; 
    src: url('./MonumentExtended-Regular.otf') format('truetype'); 
    
    font-style: normal;
  }

  @font-face {
    font-family: 'Rosseta'; 
    src: url('./Rosseta.otf') format('truetype'); 
    
    font-style: normal;
  }

.nav{
    
    z-index: 2;
    transition: background-color 0.3s ease;
}

.navbar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
    color: white;
    height: 100px;
}

.logo{
    text-decoration: none;
    color: #fff;
    font-family: "Monument";
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 25px;
}

.menu-toggle{
    cursor: pointer;
}

.nav-overlay{
    position: fixed;
    background: #18181c;
    z-index: -2;
    top: -100%;
    left: 0;
    width: 100%;
    height: 30vh;
    overflow: hidden;
    padding: 4em;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.hamBox{
    position: relative;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s ease;
    padding: 2.4em;
}

.hamBox:hover{
    background: #2a2a2f;
}

.hamBoxOpen {
    background: #2a2a2f;
}

.lineTop, .lineBottom{
    margin: 0 auto;
    position: absolute;
    display: block;
    width: 24px;
    height: 2px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.4s;
}

.lineTop{
    top: 2.2em;
}

.lineBottom{
    bottom: 2.2em;
}

.lineTop.spin{
    top: 2.35em;
    transform: rotate(135deg);
    background: #fff;
}
.lineBottom.spin{
    top: 2.35em;
    transform: rotate(225deg);
    background: #fff;
}


ul.nav-links{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    display: flex;
    gap: 30px;
    
    
}

ul.nav-links li {
    list-style: none;
    position: relative;
}

ul.nav-links li a{
    text-decoration: none;
    color: #fff;
    font-family: "Monument";
    font-size: 1em;
    position: relative;
    top: 0;
    transition: all 2s cubic-bezier(0.16, 1, 0.1, 1);
}



.nav-item-wrapper:after{
    content: "";
    position: absolute;
    top: 120px;
    left: -10%;
    width: 120%;
    height: 100%;
    background: #18181c;
    margin: 0 auto;
}

.nav-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 2em;
    display: flex;
    justify-content: space-between;
}

.nav-social-media ul{
    display: flex;
}

.nav-social-media ul li {
    list-style: none;
}

.nav-social-media ul li a {
    position: relative;
    bottom: -20px;
    text-decoration: none;
    font-family: sans-serif;
    color: #fff;
    text-transform: uppercase;
    padding-left: 2em;
    opacity: 0;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.location{
    position: relative;
    bottom: -20px;
    opacity: 0;
    transition: all 2s cubic-bezier(0.16,1 ,0.3, 1);
    font-family: sans-serif;
    text-transform: uppercase;
}

@media(max-width: 900px){
    ul.nav-links li a{
        font-size: 5em;
    }

    .location{
        display: none;
    }

    .nav-footer{
        justify-content: space-around;
    }

    .nav-social-media ul li a {
        padding: 0 1em;
    }
}