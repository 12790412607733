@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@400;500;600&display=swap');
@font-face {
  font-family: 'Dolce'; 
  src: url('./Dolce\ Gargia.otf') format('truetype'); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Agraham'; 
  src: url('./Agraham.otf') format('truetype'); 
  font-weight: 200;
  font-style: normal;
}

body {
  margin: 0;

}




.bg img{
  width: 100%;
  height: 100vh;
}

.woman-section{
  width: 100%;
  height: 100vh;
}

.woman-contenu{
  position: absolute;
  bottom: 50px;
  left: 112px;
  line-height: 60px;
}
.woman-contenu .title{
  color: rgb(248, 244, 244);
  text-shadow: 1px 1px 3px black;
  font-size: 15em;
  font-weight: 400;
  font-family: Geologica;
  letter-spacing: 10px;
}
.woman-contenu .sub-title{
  color: rgb(248, 244, 244);
  text-shadow: 1px 1px 3px black;
  font-size: 3em;
  font-weight: 200;
  font-family: Geologica; 
  padding-left: 18px;
}

.section2{
  height: 100vh;
  background-color: red;
}

.woman-products{
  position: absolute;
  top: 910px;
  left: 112px;
  line-height: 60px;
}
.woman-products .title{
  color: rgb(14, 14, 14);
  text-shadow: 1px 1px 3px black;
  font-size: 11em;
  font-weight: 400;
  font-family: Geologica;
  letter-spacing: 10px;
}


.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #2e3031, #203a43, #0f2027);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  color: #191654;
  z-index: 300;
}
.logo{
  display: flex;
}

#svg {
  height: 150px;
  width: 150px;
  stroke: white;
  fill-opacity: 0;
  stroke-width: 3px;
  stroke-dasharray: 4500;
  animation: draw 8s ease;
}
@keyframes draw {
  0% {
    stroke-dashoffset: 4500;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.name-container {
  height: 30px;
  overflow: hidden;
}

.logo-name {
  color: #fff;
  font-size: 20px;
  letter-spacing: 12px;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: bolder;
}




.winterClothes{
  height: 100vh;
  display: flex;
}

.bg2{
  width: 100%;
  position: relative;
  overflow: hidden;
}
.bg2 img{
  width: 100%;
  height: 100vh;
  filter: drop-shadow(5px 5px 10px #555);
}
.bg2 .w-img{

  filter: blur(5px);
  z-index: -1;
}

.winter-content{
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: white;
  font-size: 5rem; 
  font-family: Dolce;
  width: 100%;
  text-align: center;
  font-weight: 700;
  letter-spacing: 30px;
  z-index: 10;
  background: linear-gradient(90deg, #90EE90, #fff, #000);
  animation: animate 5s infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  line-height: 4;
}

.summer-content{
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: white;
  font-size: 5rem; 
  font-family: Dolce;
  width: 100%;
  text-align: center;
  font-weight: 700;
  letter-spacing: 30px;
  z-index: 10;
  background: linear-gradient(90deg, #90EE90, #fff, #000);
  animation: animate 5s infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  line-height: 4;
}


.model{
  position: absolute;
  top: 35%;
  left: 50%; 
  transform: translate(-50%, -50%); 
  height: 400px;
}
.description{
  position: absolute;
  bottom: 2rem;
  left: 4rem;
  width: 50%;
  font-weight: 400;
  color: #444;
  font-family: Agraham;
  font-size: 10px;
}
.s-description{
  position: absolute;
  bottom: 2rem;
  left: 4rem;
  width: 50%;
  font-weight: 400;
  color: #fff;
  font-family: Agraham;
  font-size: 10px;
}
.slog{
  position: absolute;
  top: 2rem;
  right: 4rem;
  width: 30%;
  font-weight: bold;
  font-family: Agraham;
  font-size: 20px;
  text-align: right;
}

.slog3{
  position: absolute;
  top: 4rem;
  right: 4rem;
  width: 30%;
  font-weight: bold;
  font-family: monospace;
  font-size: 40px;
  text-align: right;
  color: white;
}

