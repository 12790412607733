body {
  margin: 0;
}



.bg img{
  width: 100%;
  height: 100vh;
}

@media  screen and (max-width: 430px) {
  .winter-content{
    font-size: 35px;
  }
  .slog{
    font-size: 15px;
    width: 200px;
  }
  .description{
    position: absolute;
    bottom: 2rem;
    left: 3rem;
    width: 70%;
  }
}