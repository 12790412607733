@import url('https://fonts.googleapis.com/css2?family=Inder&display=swap');



@font-face {
  font-family: 'Agraham'; 
  src: url('./Agraham.otf') format('truetype'); 
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Dolce'; 
  src: url('./Dolce\ Gargia.otf') format('truetype'); 
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
}
.main{
  width: 100%;
  margin: 0;
}


.i-logo{
  font-size: 42px;
  font-weight: bold;
  font-style: italic;
  color: rgb(255, 255, 255);
  position: absolute;
  left: 20px;
  font-family: Inder;
  z-index: 100;
}

.first-section {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;

}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(0.68);
}
.bg video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contenu {
 
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.contenu .title{
  color: rgb(248, 244, 244);
  text-shadow: 1px 1px 3px black;
  font-size: 9em;
  font-weight: 400;
  font-family: Agraham;
  height: 227px;
  display: flex;
  align-items: center;
}
.contenu .sub-title{
  color: rgb(248, 244, 244);
  text-shadow: 1px 1px 3px black;
  font-size: 1em;
  font-weight: 200;
  font-family: Agraham;
  margin-left: 50px;
}

.btn-vd{
  z-index: 1;
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 10px;
  right: 40px;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 3em;
}
.btn-disc{
  width: 150px;
  height: 70px;
  position: absolute;
  bottom: 20px;
  left: 5%;
  cursor: pointer;
  border-radius: 25px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 2em;
}


.wrap {
 
  position: absolute;
  bottom: 45px;
  left: 8%;
}

.button {
  min-width: 300px;
  min-height: 60px;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #fff;
  background: transparent;
  border: none;
  border-radius: 1000px;

  transition: all 0.3s ease-in-out 0s;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
  }

.button::before {
content: '';
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #00FFCB;
  box-shadow: 0 0 60px rgba(0,255,203,.64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.button:hover, .button:focus {
  color: #ffff;
  transform: translateY(-6px);
}

.button:hover::before, .button:focus::before {
  opacity: 1;
}

.button::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 4px solid #00FFCB;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.button:hover::after, .button:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}



/*---------------------------------------------- Women Section -------------------------------------------*/
.w-info {
  min-height: 100vh;
}

.w-gallery {
  width: 100%;
  height: 45%;
  background-color: rgb(18, 18, 19);
  display: flex;
}

.w-gallery .pic1 {
  width: 25%;
  height: 100%;
  position: relative;
}

.w-gallery .pic1 img {
  width: 100%;
  height: 260px;
  filter: grayscale(100%);
}

.w-gallery .pic1:hover img {
  filter: grayscale(0%);
}

.dark::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.w-title {
  width: 100%;
  height: 55%;
  background-color: #161515;
  padding-top: 40px;
  position: relative;
  display: flex; 
}

.w-title h1 {
  margin-top: 0px;
  color: white;
  font-size: 3rem;
  font-family: Dolce;
  margin-left: 250px;
  width: 50%;
  line-height: 3;
}

.w-title span {
  margin-left: 150px;
}

.w-parag {
  width: 400px;
  height: 100px;
  position: absolute;
  top: 80px; 
  right: 200px;
  margin-left: 20px; 
}

.w-parag p {
  font-size: 22px;
  color: darkgrey;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 100;
  text-align: left;
}

.w-parag:hover p {
  color: #00FFCB;
}



/* Media query for Women section */
@media (max-width: 868px) {
  .w-title h1 {
    margin-top: 0px;
    font-size: 2rem;
    font-family: Dolce;
    margin-left: 50px;
    width: 50%;
    text-align: center;
    
  }

  .w-title span{
    margin-left: 0px;
  }

  .w-parag{
    width: 400px;
    height: 100px;
    position: absolute;
    top: 200px;
    right: 50px;
  }

}

/*------------------------------------------------ Men section ------------------------------------------*/
.m-info{
  min-height: 100vh;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
 
  align-items: center;
  position: relative;
}
.m-title{
  width: 800px;
  height: 140px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}


.m-title h1{
  font-family: Dolce;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 2rem;
  color: white;
  position: relative;
  z-index: 10;
}
.m-title .b{
  color: #161515;
}

.cards{
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  
  
}
.cards .card1{
  border-radius: 35px;
  display: grid;
}
.cards .card1:hover{
  box-shadow: 5px 5px 25px white;
}
.card-action{
  display: flex;
  justify-self: center;
 
}



.m-info .pic1 img{
  width: 250px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-right-radius: 50%;
  z-index: 100;
  transition: border-radius 0.3s ease-in-out;
}

.m-info .pic1:hover img{
  border-radius: 25px;
 }

.m-info .pic2 img{
  width: 250px;
  height: 300px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  transition: border-radius 0.3s ease-in-out;
}
.m-info .pic2:hover img{
 border-radius: 25px;
}

.m-info .pic3 img{
  width: 250px;
  height: 300px;
  position: absolute;
  top: 250px;
  left: 100px;
}

.m-info .pic4 img{
  width: 150px;
  height: 190px;
  position: absolute;
  bottom: 120px;
  right: 150px;
}

.m-info .pic5 img{
  width: 150px;
  height: 190px;
  position: absolute;
  top: 20px;
  right: 520px;
}

.m-info .pic6 img{
  width: 150px;
  height: 190px;
  position: absolute;
  bottom: 35px;
  left: 650px;
}

/* Media query for Men section */
@media (max-width: 430px) {
  .m-title h1{
    font-size: 1rem;
  }
  .m-info .pic1 img{
    width: 150px;
    height: 200px;
    position: absolute;
    top: 300px;
    left: 200px;
    border-bottom-right-radius: 0%;
  }

  .m-info .pic2 img{
    width: 150px;
    height: 200px;
    position: absolute;
    top: 300px;
    left: 380px;
    border-bottom-left-radius: 0%;
    border-top-right-radius: 0%;
  }

  .m-info .pic3 img{
    width: 150px;
    height: 200px;
    position: absolute;
    top: 300px;
    left: 20px;
  }

  .m-info .pic4 img{
    width: 150px;
    height: 200px;
    position: absolute;
    top: 300px;
    left: 560px;
    transition: border-radius 0.3s ease-in-out;
  }

  .m-info .pic5 img{
    width: 150px;
    height: 190px;
    position: absolute;
    top: 5px;
    left: 20px;
  }
  
  .m-info .pic6 img{
    width: 150px;
    height: 190px;
    position: absolute;
    top: 5px;
    left: 200px;
  }
  .m-title .b{
    color: #edeaea;
   
  }

  .m-info .card1{
    margin-bottom: 20px;
  }

  .m-info .card2{
    display: none;
  }

  .contenu .title{
    font-size: 6em;
  }
  .silder-container{
    display: none;
  }
  .w-parag p {
    font-size: 22px;
    color: darkgrey;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    text-align: center;
    margin-left: 82px;
    margin-top: 40px;
}
.w-info {
  height: 79vh;
}
}




.silder-container{
  width: 100%;
  background-color: #222;
  height: 100vh;
  position: relative; 
  
}

.slider{
  width: 70%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 90%;
  text-align: center;
  
}
.s-title{
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-title h2{
  font-family: Dolce;
  font-size: 2rem;
  color: #fff;
}

.spic img{
  width: 100%;
  height: 300px;
  object-fit: cover;
 
}

.con{
  background-color: #222;
  padding: 0 !important;
}

.wrapper {
  position: relative;
  height: 300px;
  width: 100%;
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: all 200ms ease-in-out;
}

.image-wrapper {
  height: 400px;
  width: 450px;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.image-wrapper img {
  height: 800px;
  width: 450px;
  object-fit: cover;
  object-position: top;
  
  transition: all 200ms ease-in-out;
}

.book-design-image {
  object-position: center;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 300px;
  width: 300px;
  left: 700px;
  transition: all 200ms ease-in-out;
  overflow: hidden;

}

.header-wrapper h1 {
  position: relative;
  bottom: 0;
  height: 300px;
  color: #fff;
  font-size: 80px;
  font-family: Oswald;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;
}

.wrapper:hover img {
  transform: scale(2);
  /*object-position: bottom;*/
}

.wrapper:hover h1 {
  bottom: 300px;
  transform: scale(.4);
}

.wrapper:hover .image-wrapper {
  width: 800px;
}



