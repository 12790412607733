/* Combined styles */
.nav2 {
  background-color: transparent;
  width: 70%;
  position: absolute;
  right: 0;
  display: flex;
  padding: 20px;
  z-index: 100;

}

.nav2 .links {
  width: 847px;
  display: flex;
  justify-content: end;
}
.nav2 .links2 {
  width: 107px;
  display: flex;
  
}

.nav2 .links ul {
  display: flex;
  justify-content: space-between;
 
  font-size: 5px;
  line-height: normal;
  list-style-type: none;
  padding: 0;
}

.nav2 .links ul li {
  margin-right: 50px;
  position: relative;
  font-size: 22px;
}

.nav2 .links ul li a {
  text-decoration: none;
  color: white;
}

.nav2 .links2 ul {
  display: flex;
  justify-content: space-between;
  
  font-size: 26px;
  line-height: normal;
  list-style-type: none;
  padding: 0;
}

.nav2 .links2 ul li {
  margin-right: 50px;
  position: relative;
  
}

.nav2 .links2 ul li a {
  text-decoration: none;
  color: white;

}

/* New styles for the dropdown */
.nav2 .links .dropdown {
  position: relative;
  display: inline-block;
}

.nav2 .links .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px; /* Add border radius */
  overflow: hidden; /* Hide overflowing content */
}

.nav2 .links .dropdown-content::before {
  content: "";
  position: absolute;
  top: -10px;
  left: calc(50% - 10px);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #f9f9f9 transparent; /* Create an arrow */
}

.nav2 .links .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.nav2 .links .dropdown-content a:hover {
  background-color: #f1f1f1;
}

.nav2 .links .dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn{
  background-color: transparent;
  border: none;
  font-size: 20px;

  color: white;
}